import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import s from './SponsorInfo.module.css';

const SponsorInfo = props => (
  <div className={s.event}>
    
      <Row className="no-gutters p-0">
        <Col className={s.sponsorCol}>
          <div className={s.sponsorImgCrop}>
            <img src={props.logo_url || `https://embed.venyou.no/img/placeholder.png`} alt="Sponsor" className={s.sponsor} />
          </div>
        </Col>
        {/*<Col className="col pl-2">
        <h5 className={s.title}>{props.name}</h5>
          {props.title && (
            <small className={s.body}>{props.title}</small>
          )}
          {props.url && (
            <small className={s.body} dangerouslySetInnerHTML={{__html: props.url}} />
          )}
        </Col>*/}
      </Row>
    
  </div>
)

SponsorInfo.propTypes = {
  url: PropTypes.string,
  logo_url: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
}

SponsorInfo.defaultProps = {
  logo_url: null,
}

export default SponsorInfo;
