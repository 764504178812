import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import s from './WeeklyGrid.module.css';
import { Row, Col } from 'reactstrap';
import EventItem from 'components/EventItem';
import Event from 'routes/Event';
import * as moment from 'moment';
import {getStartTime} from '../../helpers';

function WeeklyGrid(props) {
  const [getDate, setDate] = useState(getStartTime(props.eventDates[0]));
  const id = useParams().id || props.events[0].id;

  const selectDate = time => {
    setDate(time);
  }

  return(
    <Row className="mt-4 pl-1">
      <Col className={s.dateCol}>
        <Row>
          {props && props.eventDates && Array.isArray(props.eventDates) && props.eventDates.map(a => getStartTime(a) &&
            <Col
              className={`${s.dateBlock} ${getStartTime(a) === getDate && s.activeBlock}`}
              onClick={() => selectDate(getStartTime(a))}
              key={a.id}
            >
              <span className={s.dateBlockDay}>
                {moment(a.starttime, 'YYYY-MM-DD HH:mm').locale(props.activeLang).format('D')}
              </span>
              <span className={s.dateBlockMonth}>
                {moment(a.starttime, 'YYYY-MM-DD HH:mm').locale(props.activeLang).format('MMM')}
              </span>
            </Col>
          )}
        </Row>
        <div>
          {props && props.events ? props.events.filter(b => getStartTime(b) && getDate && getStartTime(b) === getDate).map(e =>
            <EventItem key={e.id} data={e} narrowView />
          ) : null}
        </div>
      </Col>
      <Col className="col">
        <Event id={id} />
      </Col>

    </Row>
  )
}

export default WeeklyGrid;
