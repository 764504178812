import React, { useEffect, useRef, useContext, useState } from 'react';
import {
  HashRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
// import ScrollToTop from 'components/ScrollToTop';
import Home from 'routes/Home';
import Event from 'routes/Event';
import Speaker from 'routes/Speaker';
import s from './App.module.css';
import Spinner from 'components/Spinner';
import 'moment/locale/nb';
import BackLink from 'components/BackLink';
import useWindowDimensions from 'components/WindowDimensions';
import { store } from 'store.js';

function App(props) {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { token, eventId, mode, speaker, lang } = props.config;
  const speakerMode = mode === 'speaker' && speaker;
  const showLang = lang === 'no' ? 'no' : 'en';

  const [status, setStatus] = useState({
    loading: true,
    errors: false,
  });

  const { width } = useWindowDimensions();
  const isMobile = width <= 768;

  const fetchUserTickets = async () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const requestData = { headers };
    const res = await fetch(`${process.env.REACT_APP_API}/api/me?event_id=${eventId}`, requestData);
    res
      .json()
      .then(res => {
        setStatus({ loading: false });
        dispatch({ type: 'GET_USER', payload: res });
        return res;
      })
      .catch(err => dispatch({ type: 'SET_CONFIG', payload: { token: null } }));
  }

  const fetchFavorites = async () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const requestData = { headers };
    const res = await fetch(`${process.env.REACT_APP_API}/api/event/${eventId}/user/favorite`, requestData);
    res
      .json()
      .then(res => {
        setStatus({ loading: false });
        dispatch({type: 'GET_FAVORITES', payload: res.program_block_ids});
        dispatch({type: 'GET_STREAMS', payload: res.streams});
      })
      .catch(err => dispatch({type: 'SET_CONFIG', payload: { token: null }}));
  }

  useEffect(() => {
    dispatch({type: 'SET_CONFIG', payload: props.config});
    dispatch({type: 'UPDATE_LANG', payload: showLang});
    if (token) {
      fetchFavorites();
      if (eventId === '1532') { // NOR-SHIPPING
        fetchUserTickets();
      }
    }
    setStatus({ loading: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  return (
    <div className={`${s.App} program_widget_venyou_sd0`}>
      {status.loading && <Spinner />}
      {!status.loading && (
        <React.Fragment>
          {<div>
            <BackLink />
          </div>}
          <Router hashType="noslash">
              {/* <ScrollToTop toRef={ref} /> */}
              <Switch>
                <Route path="/event/:id/:pid">
                  {props.config.view === 'weekly-grid' && !isMobile ? <Home /> : <Event />}
                </Route>
                <Route path="/event/:id">
                  {props.config.view === 'weekly-grid' && !isMobile ? <Home /> : <Event />}
                </Route>
                <Route path="/speaker/:id">
                  <Speaker />
                </Route>
                <Route path="*">
                  {speakerMode ? <Redirect to={`/speaker/${speaker}`} /> : <Home />}
                </Route>
              </Switch>
          </Router>
        </React.Fragment>
      )}
    </div>
  );
}

export default App;
