import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import EventInfoBar from 'components/EventInfoBar';
import Live from 'components/Live';
import FavoriteButton from 'components/FavoriteButton';
import { store } from 'store.js';
import s from './ProgramItem.module.css';
import { getStartTime, withRepeats } from '../../helpers';

const ProgramItem = props => {
  const globalState = useContext(store);
  const { state } = globalState;
  const conditionalFavorite = !props.narrowView ? 'col-md-auto justify-content-end order-md-last' : null;
  const availStream =
    state.streams &&
    state.streams.length > 0 &&
    state.streams.filter(a => a.program_block_id === parseInt(props.data.id)).length > 0 &&
    state.streams.filter(a => a.program_block_id === parseInt(props.data.id))[0];

  const events = props.showRepeats ? withRepeats([props.data], true) : [props.data];

  return events.map((event, index) => {
    const linkUrl = event.programId ? `/event/${props.data.id}/${event.programId}` : `/event/${props.data.id}`;

    return (
      event.published && getStartTime(event) &&
      <Link key={`link-${event.id}-${index}`} to={linkUrl}>
        <div className={`${s.event} ${props.highlighted ? s.highlighted : null}`}>
          <Row>
              <Col className={`col-12 mb-2 ${conditionalFavorite}`}>
                <Row>
                <Col><FavoriteButton id={event.id} /></Col>
                  {availStream && availStream.is_live && (
                      <Col className="col-auto"><Live left={0} top={2.5} /></Col>
                  )}
                  {availStream && !availStream.is_live && (
                      <Col className="col-auto"><Live pastStream left={0} top={2.5} /></Col>
                  )}
                </Row>
              </Col>
            <Col className="col-12 col-md">
              <EventInfoBar
                start={event.starttime}
                end={event.endtime}
                location={event.venue}
                onlyTime={props.onlyTime}
                fullWidth
              />
            </Col>
          </Row>
            <h3 className={s.title}>{event.title}</h3>
            <small className={s.body}>{event.lead}</small>
          <div className={s.speakers}>
            {event && event.speakers && Array.isArray(event.speakers) && event.speakers.map(speaker => (
                <div key={speaker.id} className={s.speakerImgCrop}>
                  <img
                    src={speaker.thumb_url || `https://embed.venyou.no/img/placeholder.png`}
                    alt={speaker.name}
                    title={speaker.name}
                    className={s.speaker}
                  />
                </div>
            ))}
          </div>
        </div>
      </Link>
    );
  })
};

export default ProgramItem;
