import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import s from './SpeakerCard.module.css';

const SpeakerCard = props => (
  <div className={s.event}>
    <Link to={`/speaker/${props.id}`}>
      <Row className="no-gutters p-0">
        <Col className={s.speakerCol}>
          <div className={s.speakerImgCrop}>
            <img src={props.thumb || `https://embed.venyou.no/img/placeholder.png`} alt="Speaker" className={s.speaker} />
          </div>
        </Col>
        <Col className="col pl-2">
          <h5 className={s.title}>{props.name}</h5>
          {props.title && (
            <small className={s.body}>{props.title}</small>
          )}
          {props.company && (
            <small className={s.body} dangerouslySetInnerHTML={{__html: props.company}} />
          )}
        </Col>
      </Row>
    </Link>
  </div>
)

SpeakerCard.propTypes = {
  id: PropTypes.number.isRequired,
  thumb: PropTypes.string,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

SpeakerCard.defaultProps = {
  thumb: null,
}

export default SpeakerCard;
