import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ProgramItem from 'components/ProgramItem';
import { Row, Col } from 'reactstrap';
import { store } from 'store.js';
import Spinner from 'components/Spinner';
import s from './Speaker.module.css';

function Speaker() {
  const { id, pid } = useParams();
  const globalState = useContext(store);
  const { state } = globalState;
  const eventId = state.config.eventId;

  const [status, setStatus] = useState({
    loading: true,
    errors: false,
  });
  const [data, setData] = useState([]);

  async function fetchData() {
    const res = await fetch(`${process.env.REACT_APP_API}/v1/public/events/${eventId}/speakers/${id}?embed=program_blocks`);
    res
      .json()
      .then(res => {
        setData(res);
        setStatus({ loading: false });
      })
      .catch(err => setStatus({loading: false, errors: err}));
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.root}>
      {status.loading && <Spinner />}
      {!status.loading && !status.errors && (
        <React.Fragment>
          <div className={s.profile}>
            <Row className="no-gutters">
              <Col className="col-12 col-md-4">
                <img src={data.thumb_url || `https://embed.venyou.no/img/placeholder_portrait.png`} alt={data.name} />
              </Col>
              <Col className={`${s.taglineBg} col-12 col-md-8 align-self-md-stretch`}>
                <div className={s.taglineBgContent}>
                  <h1 className={s.title}>{data.name}</h1>
                  <p className={s.taglineDesktop}>
                    {data.title}<br />
                    {data.company}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-3 pt-3">
            <p className={s.taglineMobile}>
              {data.title}<br />
              {data.company}
            </p>
            <p className="my-md-3" dangerouslySetInnerHTML={{__html: data.bio}} />
          </div>
          {data.program_blocks && data.program_blocks.length > 0 && (
            <div className="mt-4 pt-1">
              <h4 className="pb-2">See {data.name} at</h4>
              {data && data.program_blocks && Array.isArray(data.program_blocks) ? data.program_blocks.map(e => <ProgramItem key={e.programId ? `${e.id}_${e.programId}` : e.id} data={e} showRepeats={true} pid={pid} />) : null}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default Speaker;
