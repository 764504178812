import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import globalTranslations from './global.translation.json';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import { StateProvider } from './store.js';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: "https://f9d34131d03f4bc7b367f3e922c2a9d6@o466034.ingest.sentry.io/5628132",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const widgetRoot = document.getElementById('program-widget');
const eventId = widgetRoot ? widgetRoot.getAttribute('data-eventId') : null;

if (widgetRoot && eventId) {
  const lang = widgetRoot.getAttribute('data-lang') || 'no';
  const token = widgetRoot.getAttribute('data-token');
  const mode = widgetRoot.getAttribute('data-mode');
  const speaker = widgetRoot.getAttribute('data-speaker');
  const days = widgetRoot.getAttribute('data-show-days');
  const category = widgetRoot.getAttribute('data-category');
  const useFilters = widgetRoot.getAttribute('data-filters') === "false" ? false : true;
  const view = widgetRoot.getAttribute('data-view') || 'daily';
  const config = { eventId, lang, token, mode, speaker, days, category, view, useFilters };

  ReactDOM.render((
    <BrowserRouter>
      <StateProvider>
        <LocalizeProvider
            initialize={{
              languages: [
                { name: 'Norwegian', code: 'no' },
                { name: 'English', code: 'en' },
              ],
              translation: globalTranslations,
              options: {
                renderInnerHtml: true,
                defaultLanguage: lang,
                renderToStaticMarkup: ReactDOMServer.renderToStaticMarkup,
              },
            }}
          >
          <App config={config} />
        </LocalizeProvider>
      </StateProvider>
    </BrowserRouter>
  ), widgetRoot);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
