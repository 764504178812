import React, { useEffect, useRef, useState, useContext, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import EventInfoBar from 'components/EventInfoBar';
import * as moment from 'moment';
import Spinner from 'components/Spinner';
import FavoriteButton from 'components/FavoriteButton';
import Live from 'components/Live';
// import StreamButton from 'components/StreamButton';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import SubEventItem from 'components/SubEventItem';
import SpeakerCard from 'components/SpeakerCard';
import SponsorInfo from 'components/SponsorInfo';
import useWindowDimensions from 'components/WindowDimensions';
import ProgramItem from 'components/ProgramItem';
import s from './Event.module.css';
import { store } from 'store.js';
import { withRepeats } from '../../helpers';

const ProgramNavigation = props => {
  const { id, pid } = useParams();
  const { dispatch } = props.globalState;
  const [status, setStatus] = useState({
    loading: true,
    errors: false,
  });

  async function fetchEvents() {
    const res = await fetch(`${process.env.REACT_APP_API}/v1/public/events/${props.eventId}/program/blocks`);
    res
      .json()
      .then(res => {
        setStatus({ loading: false });
        dispatch({ type: 'FETCH_EVENTS', payload: res });
      })
      .catch(err => setStatus({ loading: false, errors: err }));
  }

  const events = useMemo(() => {
    return withRepeats(props.globalState.state.events, true);
  }, [props.globalState.state.events]);

  useEffect(() => {
    fetchEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Scroll to selected event
   */
  useEffect(() => {
    if (!id) {
      return;
    }

    const sidebarNavigation = document.getElementById('sidebar-navigation');
    const currentProgramLink = pid ? `a[href="#event/${id}/${pid}"]` : `a[href="#event/${id}"]`;
    const currentProgramLinkElement = document.querySelectorAll(currentProgramLink);

    if (currentProgramLinkElement.length && sidebarNavigation) {
      sidebarNavigation.scrollTop = currentProgramLinkElement[0].offsetTop;
    }
  }, [events]);

  return (
    <div className={s.eventList}>
      {status.loading ? (
        <div
          style={{ position: 'absolute', top: 0, left: '50%' }}
        >
          <Spinner />
        </div>
      ) : (
         events && Array.isArray(events) && events.map(e => {
           const isHighlighted = props.pid ? e.programId === parseInt(props.pid) : !e.programId && e.id === parseInt(props.fetchId);

           return (
            <ProgramItem 
              key={e.programId ? `${e.id}_${e.programId}` : e.id} 
              data={e} 
              highlighted={isHighlighted} 
              narrowView 
              onlyTime 
            />
          ); 
        })
      )}
    </div>
  )
}

const ProgramInfo = props => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);
  const ref = useRef(null);
  const { height, width } = useWindowDimensions();
  const [data, setData] = useState([]);

  async function fetchData() {
    const res = await fetch(`${process.env.REACT_APP_API}/v1/public/events/${props.eventId}/program/blocks/${props.id}`);
    res
      .json()
      .then(res => setData(res))
      .catch(err => console.info('err', err));
  }

  useEffect(() => {
    fetchData();
  }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const availStreamList =
    props.streams &&
    props.streams.length > 0 &&
    props.streams.filter(a => a.program_block_id === parseInt(props.id)).length > 0 &&
    props.streams.filter(a => a.program_block_id === parseInt(props.id))[0];

  let availStream = false;

  // NOR-SHIPPING 2022
  if (props.eventId === '1532') {
    const user = props.user;

    if (user) {
      const allTickets = [...user.purchased, ...user.shared];
      const userTickets = allTickets.filter(ticket => ticket.user_id === user.id || !ticket.user_id)
      const validTicket = userTickets.length > 0 ? userTickets.filter(t => t.ticket_id === 1077) : [];
      if (validTicket && validTicket.length > 0) {
        availStream = availStreamList;
      }
    }
  } else {
    availStream = availStreamList;
  }

  const showStream = availStream && availStream.video_url;
  const showChat =
    showStream &&
    availStream.chat_url &&
    availStream.is_live &&
    availStream.chat_url.length > 1;

  const handleTabChange = (event, newValue) => {
    props.setTabValue(newValue);
    if (newValue === 'chat' && !props.chatHeight && width < 768) {
      setTimeout(() => {
        const excessHeight = ref.current.offsetHeight;
        const newChatHeight = height - excessHeight - 80;
        props.setChatHeight(newChatHeight);
      }, 0)
    }
  };

  const itemStarttime = props.pid  && data && data.repeats ? data.repeats.find(r => r.id === parseInt(props.pid)) : data;
  const itemEndtime = props.pid && data && data.repeats ? data.repeats.find(r => r.id === parseInt(props.pid)) : data;

  return itemStarttime && itemStarttime.starttime && itemEndtime && itemEndtime.endtime ? (
    <div ref={ref}>
    <Row className="no-gutters">
      {showStream && (
        <Col className="col-12 embed-responsive h-100 embed-responsive-16by9">
          {!videoLoaded && (
            <div
              style={{ position: 'absolute', top: 0, left: '50%' }}
            >
              <Spinner />
            </div>
          )}
          <iframe
            ref={videoRef}
            src={availStream.video_url}
            frameBorder="0"
            className="embed-responsive-item"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            onLoad={() => setVideoLoaded(true)}
            title="Stream"
          />
        </Col>
      )}
      {showChat && (
        <Col className="col-12 h-100 d-md-none">
          <Paper square className={s.tabBar}>
            <Tabs
              value={props.tabValue}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleTabChange}
            >
              <Tab label="Info" value="info" />
              <Tab label="Chat" value="chat" />
            </Tabs>
          </Paper>
        </Col>
      )}
      <Col className="col-12 px-4">
        <div className={`${props.tabValue === 'chat' && s.displayNone}`}>
          <h1 className={s.title}>{data.title} {availStream && availStream.is_live && <Live top={-2.5} />}</h1>
          <div className="mt-2 pt-2 mb-2 pb-1">
            <EventInfoBar
              start={itemStarttime.starttime}
              end={itemEndtime.endtime}
              location={data.venue}
            />
          </div>
          <Row>
            <Col className="col-auto">
              <FavoriteButton id={parseInt(props.id)} />
            </Col>
          </Row>
          <div className="mt-3" style={{ wordBreak: 'break-word' }}>
            <p dangerouslySetInnerHTML={{ __html: data.body }} />
          </div>
          {data.speakers && data.speakers.length > 0 && (
            <div className="mt-4 pt-1">
              <h4><Translate id="speakers" /></h4>
              <Row>
                {data && data.speakers && Array.isArray(data.speakers) && data.speakers.map(speaker =>
                  <Col key={speaker.id} className="col-12 col-md-6 mb-md-3">
                    <SpeakerCard
                      id={speaker.id}
                      name={speaker.name || ''}
                      thumb={speaker.thumb_url}
                      title={speaker.title || ''}
                      company={speaker.company || ''}
                    />
                  </Col>
                )}
              </Row>
            </div>
          )}
          {data.sponsor_logo_url && (
            <div className="mt-4 pt-1">
              <h4><Translate id="sponsors" /></h4>
              <Row>
                  <Col className="col-12 col-md-6 mb-md-3">
                    <SponsorInfo
                      url={data.sponsor_url || ''}
                      logo_url={data.sponsor_logo_url || ''}
                      title={data.sponsor_subtitle || ''}
                      name={data.sponsor_name || ''}
                    />
                  </Col>
              </Row>
            </div>
          )}
        </div>
      </Col>
    </Row>
    </div>
  ) : <div className='text-center'>Invalid params!</div>
}

function Event(props) {
  const { id, pid } = useParams();
  const fetchId = props.id ? props.id : id;
  const globalState = useContext(store);
  const { state } = globalState;
  const eventId = state.config.eventId;
  const [chatLoaded, setChatLoaded] = useState(false);
  const [tabValue, setTabValue] = useState('info');
  const [chatHeight, setChatHeight] = useState(0)
  const ref = useRef(null);
  const [data, setData] = useState([]);

  async function fetchData() {
    const res = await fetch(`${process.env.REACT_APP_API}/v1/public/events/${eventId}/program/blocks/${fetchId}`);
    res
      .json()
      .then(res => setData(res))
      .catch(err => console.info('err', err));
  }

  useEffect(() => {
    fetchData();
  }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps


  const [status, setStatus] = useState({
    loading: false,
    errors: false,
  });
  const [streamView, setStreamView] = useState(false);

  const streams = globalState.state.streams;
  const availStreamList =
    streams &&
    streams.length > 0 &&
    streams.filter(a => a.program_block_id === parseInt(fetchId)).length > 0 &&
    streams.filter(a => a.program_block_id === parseInt(fetchId))[0];

  let availStream = false;

  // OCEAN NOW
  if (eventId === '1546') {
    const user = globalState.state.user;

    if (user) {
      const streamDate = moment(data.starttime, 'YYYY-MM-DD HH:mm').date();
      const allTickets = [...user.purchased, ...user.shared];
      const userTickets = allTickets.filter(ticket => ticket.user_id === user.id || !ticket.user_id)
      const validTicket = userTickets.length > 0 ? userTickets.filter(t => t.title_en && !t.title_en.includes('Day 1')) : [];
      if (streamDate === 1 || (validTicket && validTicket.length > 0)) {
        availStream = availStreamList;
      }
    }
  } else {
    availStream = availStreamList;
  }
  const showStream = availStream && availStream.video_url;
  const showChat =
    showStream &&
    availStream.chat_url &&
    availStream.is_live &&
    availStream.chat_url.length > 1;

  if (!streamView && showStream && availStream.is_live) setStreamView(true);

  return (
    <div className={`${s.root} ${showChat ? s.chatVideo : null}`} ref={ref}>
      {status.loading && <Spinner />}
      {!status.loading && !status.errors && (
        <React.Fragment>
          <div className="pb-md-2" style={{ wordBreak: 'break-word' }}>
            <Row className="px-0 px-md-3">
              <Col id="sidebar-navigation" className={`col-md-3 d-xl-block mt-0 ${showChat ? 'd-md-none' : 'd-sm-none'} ${s.positionSticky}`}>
                <ProgramNavigation 
                  globalState={globalState} 
                  fetchId={fetchId} 
                  eventId={eventId} 
                  pid={pid}
                />
              </Col>
              <Col className={`col-12 ${showChat ? 'col-md-8 col-xl-6' : 'col-md-9'}`}>
                <ProgramInfo
                  id={fetchId}
                  eventId={eventId}
                  user={globalState.state.user}
                  streams={globalState.state.streams}
                  setTabValue={setTabValue}
                  chatHeight={chatHeight}
                  setChatHeight={setChatHeight}
                  tabValue={tabValue}
                  pid={pid}
                />
              </Col>
              {showChat && (
                <>
                  <Col className={`${tabValue === 'info' && s.displayNone} col-12 col-md-4 col-xl-3 ${s.positionSticky} ${s.chatContainer}`}>
                    {!chatLoaded && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: '50%',
                        }}
                      >
                        <Spinner />
                      </div>
                    )}
                    <iframe
                      src={availStream.chat_url}
                      width="100%"
                      height={chatHeight}
                      className={s.chatEmbed}
                      frameBorder="0"
                      onLoad={() => setChatLoaded(true)}
                      title="chat"
                    />
                  </Col>
                </> 
              )}
            </Row>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Event;
