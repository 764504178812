import React, { useContext } from 'react';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import s from './EventInfoBar.module.css';
import { store } from 'store.js';

const EventInfoBar = props => {
  const globalState = useContext(store);
  const lang = globalState.state.lang;
  const startTime = props.start ? moment(props.start, 'YYYY-MM-DD HH:mm') : moment();
  const endTime = props.end ? moment(props.end, 'YYYY-MM-DD HH:mm') : moment();
  const sameDay = startTime.isSame(endTime, 'day');
  const timeString = sameDay ?
    `${startTime.locale(lang).format('D. MMM, HH:mm')} - ${endTime.locale(lang).format('HH:mm')}` :
    `${startTime.locale(lang).format('D. MMM HH:mm')} - ${endTime.locale(lang).format('D. MMM HH:mm')}`

  let classes = props.fullWidth ? `${s.eventInfo} ${s.full}` : s.eventInfo;
  classes = !props.bg ? `${classes} ${s.noBg}` : classes;

  return (
    <div className={classes}>
      {props.start && (
        <h5 className={s.time}>{timeString}</h5>
      )}
      {props.location && !props.onlyTime && (
        <h5 className={s.location}>{props.location}</h5>
      )}
    </div>
  )
}

EventInfoBar.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  location: PropTypes.string,
  fullWidth: PropTypes.bool,
  bg: PropTypes.bool,
  onlyTime: PropTypes.bool,
}

EventInfoBar.defaultProps = {
  start: null,
  end: null,
  location: null,
  onlyTime: false,
  fullWidth: false,
  bg: true,
}

export default EventInfoBar;
