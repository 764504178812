import React, { useState, useEffect, useContext } from "react";
import s from "./Home.module.css";
import { Row, Col } from "reactstrap";
import Filter from "components/Filter";
import ProgramItem from "components/ProgramItem";
import * as moment from "moment";
import Spinner from "components/Spinner";
import WeeklyGrid from "./WeeklyGrid";
import useWindowDimensions from "components/WindowDimensions";
import { store } from "store.js";
import {getStartTime, withRepeats} from '../../helpers';

function uniqueDate(array) {
  const result = [];
  const map = new Map();
  let filterItem;
  for (const item of array) {
    filterItem = getStartTime(item);

    if (item && filterItem && !map.has(filterItem)) {
      map.set(filterItem, true);
      result.push(item);
    }
  }

  return result;
}

function addUnique(array) {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (item && !map.has(item.id)) {
      map.set(item.id, true);
      result.push(item);
    }
  }
  return result;
}

function filterEvents(events, filter, favorites) {
  events =
    !filter.favorites || !favorites || favorites.length === 0
      ? events
      : events.filter((a) => favorites.includes(a.id));
  events =
    filter.categories && filter.categories.length > 0
      ? events.filter((a) =>
          a.categories.some((b) => filter.categories.some((c) => c.id === b.id))
        )
      : events;
  events =
    filter.venues && filter.venues.length > 0
      ? events.filter((a) => filter.venues.some((b) => a.venue === b.id))
      : events;
  events =
    filter.speakers && filter.speakers.length > 0
      ? events.filter((a) =>
          a.speakers.some((b) => filter.speakers.some((c) => c.id === b.id))
        )
      : events;
  events =
    filter.search && filter.search.length > 0
      ? events.filter((a) => {
        return a.title && a.title.toLowerCase().includes(filter.search.toLowerCase()) 
      })
        : events;

  return events;
}

function Home() {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { eventId, days } = state.config;
  let category = state.config.category;
  let useFilters = state.config.useFilters;
  const { favorites, filter } = state;
  const [status, setStatus] = useState({
    loading: true,
    errors: false,
  });

  // If category from data-category is empty, check if there is a URL parameter 'category'
  if (category === null) {
    const queryParams = new URLSearchParams(window.location.search);
    category = queryParams.get("category");
    if (category !== null) {
      useFilters = false;
    }
  }

  const { width } = useWindowDimensions();
  const view = width > 768 ? state.config.view : "daily";

  const showEvents =
    !status.loading &&
    globalState.state.events &&
    globalState.state.events.length > 0;

  async function fetchData() {
    const res = await fetch(
      `${process.env.REACT_APP_API}/v1/public/events/${eventId}/program/blocks`
    );

    res
      .json()
      .then((response) => {
        const catFiltered = category
          ? response.filter((a) =>
              a.categories.some((cat) => cat.id === parseInt(category))
            )
          : response;
        const daysFiltered = days
          ? catFiltered.filter((b) =>
              days.split(",").some((v) => b.starttime.includes(v))
            )
          : catFiltered;
        setStatus({ loading: false });
        dispatch({ type: "FETCH_EVENTS", payload: daysFiltered });

        const categories = addUnique(
          response.map(
            (a) => {
              if (a && a.categories && Array.isArray(a.categories)) {
                return a.categories.map((b) => {
                  if (b && b.name) {
                    return {
                      id: b.id,
                      label: b.name,
                      value: b.name,
                      label_en: b.en.name,
                    }
                  }
                })[0]
              }
            }
          )
        );

        const speakers = addUnique(
          response.map(
            (a) => {
              if (a && a.speakers && Array.isArray(a.speakers)) {
                return a.speakers.map((b) => {
                  if (b && b.name) {
                    return {
                      id: b.id,
                      label: b.name,
                      value: b.name,
                    }
                  }
                })[0]
              }
            }
          )
        );

        const venues = addUnique(
          response.map((a) => {
            if (a.venue) {
              return { id: a.venue, label: a.venue, value: a.venue };
            }
          })
        );

        const setFilters = { categories, speakers, venues };

        dispatch({ type: "SET_FILTER", payload: setFilters });
      })
      .catch((err) => setStatus({ loading: false, errors: err }));
  }

  useEffect(() => {
    if (
      !globalState.state.events ||
      (globalState.state.events && globalState.state.events.length === 0)
    ) {
      fetchData();
    } else {
      setStatus({ loading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const events = withRepeats(filterEvents(globalState.state.events, filter, favorites), true);

  const filtersDom = [
    {
      id: "categories",
      placeholder: "Categories",
      placeholder_no: "Kategorier",
    },
    { id: "venues", placeholder: "Scenes", placeholder_no: "Sted" },
    { id: "speakers", placeholder: "Speakers", placeholder_no: "Speakers" },
  ];

  return (
    <div className={s.root}>
      {process.env.REACT_APP_DEBUG === "true" ? (
        <div className={s.version}>
          Version: <i>{process.env.REACT_APP_VERSION}</i>
        </div>
      ) : (
        <></>
      )}
      {showEvents && (
        <React.Fragment>
          <div className="mb-2">
            <Filter
              filtersDom={filtersDom}
              favoritesAvailable={favorites && favorites.length > 0}
              useFilters={useFilters}
            />
          </div>
          {(view === "daily" || view === "colview") && (
            <Row>
              {events && Array.isArray(events) &&
                events.map((e) => (
                  <Col
                    key={e.programId ? `${e.id}_${e.programId}` : e.id}
                    className={`col-12 ${
                      view === "colview" ? "col-md-6" : null
                    }`}
                  >
                    <ProgramItem data={e} />
                  </Col>
                ))}
            </Row>
          )}
          {view === "weekly" && events && (
            <Row className="mt-5">
              {uniqueDate(events).map((a) => (
                <Col key={a.programId ? `${a.id}_${a.programId}` : a.id}>
                  <h6 className={s.dateHeadline}>
                    {moment(a.starttime, "YYYY-MM-DD HH:mm")
                      .locale(state.lang)
                      .format("dddd D. MMMM")}
                  </h6>
                  { events
                    .filter(b => getStartTime(b) === getStartTime(a))
                    .map(e => <ProgramItem key={e.programId ? `${e.id}_${e.programId}` : e.id} data={e} narrowView />)
                  }
                </Col>
              ))}
            </Row>
          )}
          {view === "weekly-grid" && events && (
            <WeeklyGrid
              eventDates={uniqueDate(events)}
              events={events}
              activeLang={state.lang}
            />
          )}
        </React.Fragment>
      )}
      {!status.loading && !showEvents && (
        <h5 className="text-center mt-5 pt-3">Program is not ready yet</h5>
      )}
      {status.loading && <Spinner />}
    </div>
  );
}

export default Home;
